import 'core-js/stable';
import 'regenerator-runtime/runtime';

import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/index.css'
import axios from 'axios'
import Cookies from 'js-cookie'
import JSONBig from "json-bigint";
import qs from 'qs'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts'
import "echarts/map/js/china.js";
import Vant from 'vant'
import 'vant/lib/index.css'
import animate from 'animate.css'
import 'swiper/css/swiper.min.css'
import Video from 'video.js'
import 'video.js/dist/video-js.css'
import 'lib-flexible/flexible.js'
Vue.prototype.$animate = animate;
Vue.prototype.$echarts = echarts;
Vue.prototype.$axios = axios
Vue.prototype.$qs = qs
Vue.prototype.$JSONBig = JSONBig
Vue.prototype.$Cookies = Cookies
Vue.config.productionTip = false
Vue.prototype.$video = Video
Vue.use(ElementUI);
Vue.use(echarts);
Vue.component('VueEcharts', echarts)
Vue.use(Vant);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

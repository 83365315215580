
<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  props: {},
  data() {
    return { isRouterAlive: true };
  },
  provide() {
    //提供
    return {
      reload: this.reload,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>


<style scoped lang="less">
</style>

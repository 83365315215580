import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'home',
        component: () =>
            import ('@/views/home')
    },
    // 新谋体运营
    {
        path: '/newMedia',
        name: 'newMedia',
        component: () =>
            import ('@/views/newMedia')
    },
    // 公司产品
    {
        path: '/product',
        name: 'product',
        component: () =>
            import ('@/views/product')
    },
    // 产品详情
    {
        path: '/product-details',
        name: 'product-details',
        component: () =>
            import ('@/views/product-details')
    },
    // 公司新闻 
    {
        path: '/newsPages',
        name: 'newsPages',
        component: () =>
            import ('@/views/newsPages')
    },
    // 新闻详情
    {
        path: '/news-details',
        name: 'news-details',
        component: () =>
            import ('@/views/news-details')
    },
    // 活动策划
    {
        path: '/activityPlanning',
        name: 'activityPlanning',
        component: () =>
            import ('@/views/activityPlanning')
    },
    // 加入我们
    {
        path: '/joinPages',
        name: 'joinPages',
        component: () =>
            import ('@/views/joinPages')
    },
    // 加入我们列表
    {
        path: '/joinList',
        name: 'joinList',
        component: () =>
            import ('@/views/joinList')
    },
    // 加入我们详情
    {
        path: '/joinDetails',
        name: 'joinDetails',
        component: () =>
            import ('@/views/joinDetails')
    },
    // 关于晨星
    {
        path: '/company',
        name: 'company',
        component: () =>
            import ('@/views/company')
    },
    // 线上活动 
    {
        path: '/online-activity',
        name: 'online-activity',
        component: () =>
            import ('@/views/online-activity')
    },
    // 线上活动详情
    {
        path: '/online-details',
        name: 'online-details',
        component: () =>
            import ('@/views/online-details')
    },
    // 公司产品
    {
        path: '/tweetPages',
        name: 'tweetPages',
        component: () =>
            import ('@/views/tweetPages')
    },
    // 客户案例 
    {
        path: '/customerCase',
        name: 'customerCase',
        component: () =>
            import ('@/views/customerCase')
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
    // chrome
    document.body.scrollTop = 0
        // firefox
    document.documentElement.scrollTop = 0
        // safari
    window.pageYOffset = 0
});
export default router
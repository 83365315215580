import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    leftTypeData: 1,//加入我们左侧栏type
    productId: '',//产品id
    productType: '',//公司产品activeName
    selectIndex: '',//关于晨星select
  },
  getters: {
  },
  mutations: {
    setType(state, payload) {
      state.leftTypeData = payload
    },
    setProductId(state, payload) {
      state.productId = payload
    },
    setProductType(state, payload) {
      state.productType = payload
    },
    setSelect(state, payload) {
      state.selectIndex = payload
    },
  },
  actions: {
  },
  modules: {
  }
})
